import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>מה הלקוחות שלנו אומרים</h2>
        </div>
        {/* Styled iframe container */}
        <div
          className="iframe-container"
          style={{
            margin: "20px auto",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#f9f9f9",
            maxWidth: "800px",
          }}
        >
          <h4 style={{ marginBottom: "10px", textAlign: "center", color: "#333" }}>
            ביקורות נוספות מהלקוחות שלנו
          </h4>
          <iframe
            src="https://www.midrag.co.il/SpCard/Sp/113323?areaId=9&serviceId=1199&sortByCategory=1484"
            title="Customer Reviews"
            width="100%"
            height="500px"
            frameBorder="0"
            style={{
              border: "none",
              borderRadius: "5px",
            }}
            allowFullScreen
          ></iframe>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "טוען..."}
        </div>
      </div>
    </div>
  );
};
