import React from "react";
import { FaFacebookF, FaWhatsapp, FaPhone } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { SiTiktok } from "react-icons/si";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
       <section id="social-links" className="socialLinks">
      <a href="tel:+972522617505" className="socialLinks__icon">
        <FaPhone />
      </a>
      <a
        href="https://wa.me/972522617505"
        target="_blank"
        rel="noopener noreferrer"
        className="socialLinks__icon"
      >
        <FaWhatsapp />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=100082352742382"
        target="_blank"
        rel="noopener noreferrer"
        className="socialLinks__icon"
      >
        <FaFacebookF />
      </a>
      <a
        href="https://www.instagram.com/ilan.dahann/"
        target="_blank"
        rel="noopener noreferrer"
        className="socialLinks__icon"
      >
        <AiFillInstagram />
      </a>
      <a
  href="https://www.tiktok.com/@.ilandahan" // Replace with the actual TikTok URL
  target="_blank"
  rel="noopener noreferrer"
  className="socialLinks__icon"
>
  <SiTiktok />
</a>
    </section>
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
        <h2 className="features-title">תכונות</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "טוען..."}
        </div>
      </div>
    </div>
  );
};
